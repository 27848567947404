import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Strong, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Inicio | SkyHigh Marketing Agency
			</title>
			<meta name={"description"} content={"Eleve su marca, alcance nuevas alturas"} />
			<meta property={"og:title"} content={"Inicio | SkyHigh Marketing Agency"} />
			<meta property={"og:description"} content={"Eleve su marca, alcance nuevas alturas"} />
			<meta property={"og:image"} content={"https://harmonichavn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://harmonichavn.com/img/5347474357.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<meta name={"msapplication-TileImage"} content={"https://harmonichavn.com/img/5347474357.png"} />
			<meta name={"msapplication-TileColor"} content={"https://harmonichavn.com/img/5347474357.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://harmonichavn.com/img/1.jpg) center/cover"
			padding="120px 0 50px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text
							color="--lightD2"
							letter-spacing="1px"
							text-transform="uppercase"
							margin="0"
							font="--headline1"
						>
							SkyHigh Marketing Agency
						</Text>
						<Text font="--headline4" margin="10px 0">
						Bienvenido a SkyHigh, donde llevamos sus esfuerzos de marketing a nuevas alturas. Nuestro equipo de expertos se compromete a ofrecer estrategias innovadoras y basadas en datos que transformen su negocio e impulsen resultados extraordinarios. Únase a nosotros en un viaje para elevar su marca y lograr un éxito sin precedentes.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="220px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
				Nuestros servicios
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						¿Por qué elegir SkyHigh?
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				Elegir la agencia de marketing adecuada es crucial para el éxito de su negocio. He aquí por qué SkyHigh destaca:
					<br />
					<br />
					<Strong>
					Equipo de expertos: {" "}
					</Strong>
					Nuestro equipo de profesionales del marketing aporta amplios conocimientos y experiencia para ofrecer un servicio y unos resultados excepcionales.
					<Strong>
						{" "}
					</Strong>
					<br />
					<Strong>
					Soluciones personalizadas: {" "}
					</Strong>
					Adaptamos nuestras estrategias para que se ajusten a sus necesidades empresariales únicas, garantizando los mejores resultados posibles.{" "}
					<br />
					<Strong>
					Enfoque innovador: {" "}
					</Strong>
					Nos mantenemos a la vanguardia aplicando las últimas tendencias y tecnologías de marketing para que su marca siga siendo competitiva.
					<br />
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://harmonichavn.com/img/2.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://harmonichavn.com/img/3.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Acerca de SkyHigh
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
				En SkyHigh entendemos el poder transformador de un marketing eficaz. Nuestra agencia se fundamenta en la creatividad, la experiencia y la incansable voluntad de alcanzar la excelencia para nuestros clientes. Con una amplia experiencia en diversos sectores, estamos preparados para gestionar empresas de todos los tamaños, ayudándoles a alcanzar nuevas cotas en sus esfuerzos de marketing.
				<br/><br/>
				Nuestro enfoque combina los principios del marketing tradicional con las últimas tendencias digitales, garantizando una estrategia integral y eficaz para cada cliente. Sabemos que cada negocio es único, por lo que adaptamos nuestros servicios para satisfacer sus objetivos y necesidades específicos. Si usted está buscando para mejorar su presencia en línea, lanzar un nuevo producto, o participar de manera más eficaz con su público, SkyHigh tiene la experiencia para hacer que suceda.

				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Comencemos
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			¿Listo para elevar su marketing a nuevas alturas? Póngase en contacto con SkyHigh hoy mismo y descubra la diferencia que puede marcar nuestra experiencia. Embarquémonos juntos en este viaje, llevando su marca a nuevas alturas y logrando un éxito sin precedentes.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Contactos
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});